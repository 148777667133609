import '../lib/globals/babel'
import '../lib/chunk_load_handler'

import loadPolyfills from '../lib/polyfills'
import { appInfo } from '@polleverywhere/slideware-bindings/lib/app_info'
import { peUrlParams } from './util/peUrlParams'
import { addInstallPromptListener } from './util/pwa'

import '../lib/init.scss'

async function bootApp () {
  const startAppLoader = (await import(/* webpackChunkName: "start-app" */ './util/startApp'))

  const info = appInfo()
  const integrationMode = info.presenter
  const params = peUrlParams()
  const opts = {
    role: 'presenter',
    appName: 'Poll Everywhere Presenter',
    outOfBandAuth: window.Capacitor?.isNative
  }

  if (params.has('mode')) {
    opts.mode = params.get('mode')
  } else if (integrationMode) {
    opts.mode = 'integration'
  }

  addInstallPromptListener()
  startAppLoader.startApp(opts)
}

loadPolyfills().then(async () => {
  if (navigator.onLine) {
    bootApp()
  } else {
    const offlineMessage = (await import(/* webpackChunkName: 'offline-message' */ './util/offlineMessage')).offlineMessage
    offlineMessage('Poll Everywhere Presenter')
  }
})
